
import type Day from '@/modules/common/types/day.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import BaseCard from './base-card.vue';

@Component({ components: { BaseCard } })
export default class SoldOut extends Vue {
    @inject(RatesServiceS)
    private ratesService!: RatesService;

    @inject(RatesAllServiceS)
    private ratesAllService!: RatesAllService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    private get hotelId() {
        return +this.$route.params.hotelId;
    }

    private get pax() {
        return !this.ratesService.isAllChannelsMode
            ? this.ratesService.getCheckinDay(this.day)?.hotels[this.hotelId]?.pax
            : this.ratesAllService.getCheckinDay(this.day)?.[this.hotelId]?.pax;
    }

    get outputText() {
        return this.pax
            ? this.$tc('nog', 0, [this.pax])
            : this.$tc('soldOut');
    }

    get outputTitle() {
        return this.pax
            ? this.$tc('rates.nogAvailable', 0, [this.pax])
            : undefined;
    }
}
